import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { HttpAppService } from './http-app.service';
import { BaseAppService } from './base-app.service';
import { IResultHttpApp } from '../interfaces/IResultHttpApp';
import { ClienteModel } from '../models/clienteModel';
import { environment } from '../environments/environment';
// import { CupomAppService } from './cupom-app.service';

@Injectable({
  providedIn: 'root'
})
export class ClienteAppService extends BaseAppService<ClienteModel> {
  private flClienteElegivelCupomSubject = new BehaviorSubject<boolean>(false);

  constructor(public override http: HttpAppService,
    // private cupomAppSrv: CupomAppService
  ) {
    super('cliente', http);
  }

  create(model: ClienteModel): Promise<IResultHttpApp> {
    return this.http.post(`${environment.url_api}/cliente/create`, model);
  }

  preValidaCliente(model: ClienteModel): Promise<IResultHttpApp> {
    return this.http.post(`${environment.url_api}/cliente/preValidaCliente`, model);
  }

  // serve para replicar a elegibilidade do cliente para cupom promoção
  // da tab-cliente-home e as páginas filhas
  observableClienteElegivelCupom(): Observable<boolean> {
    return this.flClienteElegivelCupomSubject.asObservable().pipe(distinctUntilChanged());
  }

  // serve para replicar a elegibilidade do cliente para cupom promoção
  // da tab-cliente-home e as páginas filhas
  definirElegibilidadeCuponsPromocaoCliente(flag: boolean) {
    this.flClienteElegivelCupomSubject.next(flag);
  }

  // Verifica se cliente é elegível para ter cupom de promoção
  // (se já não usou cupom promoção nos últimos n dias)
  async obterElegibilidadePromocaoCliente(clienteUid: string): Promise<boolean> {
    // está forçando elegibilidade para todos, independente se usou ou não outro cupom
    // nos últimos dias
    // essa alteração (12/9/2021) foi por causa da seguinte situação:
    //   - é interessante apresentar os descontos de campanhas mesmo para quem não tem login
    //   - e apresentando o desconto, fica ruim retirar o desconto após o login de
    //     algum cliente que já usou desconto.
    this.definirElegibilidadeCuponsPromocaoCliente(true);
    return true;
    // console.log('executou obterElegibilidadePromocaoCliente')
    // const _resultElegivel = await this.cupomAppSrv.obterUidCuponsUsadosCliente(clienteUid);
    // if (_resultElegivel) {
    //   if (_resultElegivel.success) {
    //     const _arrayUidPedidosClienteComCupons = _resultElegivel.data as Array<string>;
    //     if (_arrayUidPedidosClienteComCupons && _arrayUidPedidosClienteComCupons.length > 0) {
    //       this.definirElegibilidadeCuponsPromocaoCliente(false);
    //       return false;
    //     } else {
    //       this.definirElegibilidadeCuponsPromocaoCliente(true);
    //       return true;
    //     }
    //   } else {
    //     this.definirElegibilidadeCuponsPromocaoCliente(false);
    //     return false;
    //   }
    // }
  }

}
